export default [
  'Belgien',
  'Bulgarien',
  'Dänemark',
  'Deutschland',
  'Estland',
  'Finnland',
  'Frankreich',
  'Griechenland',
  'Irland',
  'Italien',
  'Kroatien',
  'Lettland',
  'Litauen',
  'Luxemburg',
  'Malta',
  'Niederlande',
  'Österreich',
  'Polen',
  'Portugal',
  'Rumänien',
  'Schweden',
  'Slowakei',
  'Slowenien',
  'Spanien',
  'Tschechien',
  'Ungarn',
  'Zypern',
  'Land außerhalb EU',
];
