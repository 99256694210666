















































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import html2pdf from 'html2pdf.js';
import { calculateClaimsRequirementPremium,
  copyObject,
  getProductPageBeforePriceTabHelper,
  onCurrencyFocus,
  transformToCurrencyStr } from '@/helper/functions';
import FormSwitch from '@/components/form/form-switch.vue';
import DatePicker from '@/components/form/date-picker.vue';
import { calculatePrice } from '@/helper/priceCalculator/assembly-calculator';
import taxCountry from '@/config/tax-country-list';
import apiBackend from '@/service/api-insurance';
import xmlStatusIcon from '@/components/form/XML-status-icon.vue';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
// import priceStatusIcon from '@/components/form/price-status-icon.vue';

const resultsOfTariffCalculationItem = {
  /* Selbstbehalt */
  deductible: null,
  /* Tarifprämie */
  tariffPremium: 0,
  /* Laufzeit  */
  duration: null,
  /* Generalvertragsnachlass */
  generalContractEstate: null,
  svr: null,
  /* Betrachtungszeitraum */
  periodUnderConsideration: null,
  sQ: null,
  /* Nachlass für KDS Deckung */
  discountForKDSCoverage: null,
  /* Nettoisierung */
  netting: null,
  /* Prämie inkl. tariflicher Nachlässe */
  premiumIncludingTariffDiscounts: 0,
};

const promotionalRewardsColumns = {
  sb: 0,
  /* Prämie inkl. tariflicher Nachlässe */
  premiumIncludingTariffDiscounts: 0,
  /* Prämie inkl. Marktfaktor */
  premiumIncludingMarketFactor: 0,
  /* Angebotsprämie netto */
  netOfferPremium: 0,
  /* Angebotsprämiensatz netto */
  netOfferPremiumRate: 0,
  /* Angebotsprämie brutto */
  offerPremiumGross: 0,
};

const promotionalRewardsItems = {
  premiumAssembly: {
    pos: 'Prämie Montage',
    ...copyObject(promotionalRewardsColumns),
  },
  premiumSample: {
    pos: 'Prämie Probe',
    ...copyObject(promotionalRewardsColumns),
  },
  premiumAdditionalCoverageWithoutSiG: {
    pos: 'Prämie Zusatzdeckungen ohne SiG',
    ...copyObject(promotionalRewardsColumns),
  },
  premiumAssemblyIncludingSample: {
    pos: 'Prämie Montage inkl. Probe',
    ...copyObject(promotionalRewardsColumns),
  },
  premiumMaintenance: {
    pos: 'Prämie Maintenance',
    ...copyObject(promotionalRewardsColumns),
  },
  premiumSiG: {
    pos: 'Prämie SiG',
    ...promotionalRewardsColumns,
  },
  premiumTotal: {
    pos: 'Prämie Gesamt',
    ...copyObject(promotionalRewardsColumns),
  },
};

const countiesWithBrutto = [
  'Deutschland',
  'Land außerhalb EU',
];

export default Vue.extend({
  name: 'assembly-price-card',
  mixins: [dataChangeMixin],
  components: {
    // priceStatusIcon,
    DatePicker,
    FormSwitch,
    xmlStatusIcon,
  },
  props: [
    'id',
    'insuranceKey',
    'insuranceData',
    'processSavingData',
    'productId',
    'priceData',
    'AMSAPISendStatus',
  ],
  watch: {
    processSavingData(value) {
      if (this.insuranceData?.spreadsheetData) {
        this.initOffersRewards(this.insuranceData.spreadsheetData);
        this.initOffersRewards(this.insuranceData.spreadsheetData, 'variant2');
        this.initOffersRewards(this.insuranceData.spreadsheetData, 'variant3');
        this.inittResultsOfTariffCalculation(this.insuranceData.spreadsheetData);
      }
    },
    priceData: {
      handler(value) {
        this.onNextClick();
        this.calculatePriceFormData(value);
      },
      deep: true,
    },
    // eslint-disable-next-line func-names
    'calculationResult.steuerland': function (value: any) {
      if (!countiesWithBrutto.includes(value)) {
        this.disabledOfferPremiumGross = true;
        this.setEmtyBrutto();
      } else {
        this.disabledOfferPremiumGross = false;
      }
    },
    'promotionalRewards.variant1.items.premiumTotal.netOfferPremium': function (value: number) {
      if (value > 0) {
        this.promotionalRewards.variant1.opened = true;
      }
    },
    'promotionalRewards.variant2.items.premiumTotal.netOfferPremium': function (value: number) {
      if (value > 0) {
        this.promotionalRewards.variant2.opened = true;
      }
    },
    'promotionalRewards.variant3.items.premiumTotal.netOfferPremium': function (value: number) {
      if (value > 0) {
        this.promotionalRewards.variant3.opened = true;
      }
    },
    resultsOfTariffCalculation: {
      handler(value) {
        this.calculatePriceFormData(this.priceData);
      },
      deep: true,
    },
    insuranceData: {
      handler(value) {
        this.calculatePriceFormData(this.priceData);
      },
      deep: true,
    },
    skillDiscounts: {
      handler(value) {
        this.calculatePriceFormData(this.priceData);
      },
      deep: true,
    },
    promotionalRewards: {
      handler(value) {
        this.calculatePriceFormData(this.priceData);
      },
      deep: true,
    },
  },
  data(): any {
    return {
      key: 'ASSEMBLY_INSURANCE',
      taxCountry,
      /* Kalkulationsergebnis */
      calculationResult: {
        versicherungsnehmer: '',
        betriebsart: '',
        versicherungssummeAktuell: '',
        versicherungssumme371: '',
        produkt: '',
        nettoisiert: '',
        steuerland: 'Deutschland',
        vorversicherer: '',
        versicherungsscheinnummerVorvertrag: '',
        PML: 0,
      },
      /* Ergebnisse Tarifkalkulation */
      resultsOfTariffCalculation: {
        variant1: {
          name: 'SB-Variante 1',
          style: 'table-variant-1',
          ...resultsOfTariffCalculationItem,
          priceKey: 'variant1',
        },
        variant2: {
          name: 'SB-Variante 2',
          style: 'table-variant-2',
          ...resultsOfTariffCalculationItem,
          priceKey: 'variant2',
        },
        variant3: {
          name: 'SB-Variante 3',
          style: 'table-variant-3',
          ...resultsOfTariffCalculationItem,
          priceKey: 'variant3',
        },
      },
      vorschadenhistorieSelect: false,
      vorschadenhistorie: {
        jahr1: {
          schaden: 0,
          reserven: 0,
        },
        jahr2: {
          schaden: 0,
          reserven: 0,
        },
        jahr3: {
          schaden: 0,
          reserven: 0,
        },
        jahr4: {
          schaden: 0,
          reserven: 0,
        },
        jahr5: {
          schaden: 0,
          reserven: 0,
        },
        summe: {
          schaden: 0,
          reserven: 0,
        },
        SBVorvertrag: 0,
        Betrachtungszeitraum: '',
        Zielschadenquote: '',
        MindestjahrespramieTarifVariante: '',
        MindestjahrespramieSBVariante1: '',
        MindestjahrespramieSBVariante2: '',
        MindestjahrespramieSBVariante3: '',
        ZusatzlicherVorschadenfaktor: null,
        Begrundung: '',
      },
      skillDiscounts: {
        resultRiskFactors: '',
        variant1: {
          estates: '',
          surcharges: '',
        },
        variant2: {
          estates: '',
          surcharges: '',
        },
        variant3: {
          estates: '',
          surcharges: '',
        },
        reason: '',
      },
      vierAugenPrinzip: {
        show: false,
        discussedWith: '',
        date: '',
        notes: '',
      },
      vertragAufMietumsatzbasis: {
        plannedRentalRevenue: '',
      },

      promotionalRewards: {
        variant1:
          {
            style: 'table-variant-1',
            groupName: 'SB-Variante 1',
            opened: false,
            items: {
              ...copyObject(promotionalRewardsItems),
            },
          },
        variant2:
          {
            style: 'table-variant-2',
            groupName: 'SB-Variante 2',
            opened: false,
            items: {
              ...copyObject(promotionalRewardsItems),
            },
          },
        variant3:
          {
            opened: false,
            style: 'table-variant-3',
            groupName: 'SB-Variante 3',
            items: {
              ...copyObject(promotionalRewardsItems),
            },
          },
      },
      pdfStyles: false,
      hinweiseZumBeitrag: '',
      disabledOfferPremiumGross: false,
    };
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceData.priceFormData) {
      this.setValues(this.insuranceData.priceFormData);
    }
    this.calculatePriceFormData(this.priceData);
    (this as any).startWatcherActivated();
  },
  methods: {
    getXmlBtnIsDisable(index: string) {
      if (this.AMSAPISendStatus !== null) {
        const status = this.AMSAPISendStatus[index];
        if (status !== null) {
          return (!status.dataUpdated && !status.statusSuccess);
        }
      }
      return false;
    },
    onCurrencyFocus,
    showTextField(item: any): boolean {
      return item.pos !== 'Prämie Montage inkl. Probe' &&
        item.pos !== 'Prämie Gesamt' &&
        item.pos !== 'Prämie Zusatzdeckungen ohne SiG' &&
        item.pos !== 'Prämie SiG';
    },
    queueSendBigXML(variant) {
      this.saveZusammenfassungPDF('sendBigXML', variant);
    },
    queueDownloadBigXML(variant) {
      this.saveZusammenfassungPDF('downloadBigXML', variant);
    },
    /* queueGetPrice(variantPrice: string) {
      this.$emit('get-price-use-id', this.id, variantPrice);
      this.onNextClick();
    }, */
    saveZusammenfassungPDF(functionName: string, variant: string) {
      const element = document.getElementById('element-to-convert');
      const filename = `Zusammenfassung-Montageversicherung-Preis-${this.id}.pdf`;
      this.pdfStyles = true;
      const opt = {
        margin: 10,
        filename,
        html2canvas: {
          width: 1500,
        },
      };
      html2pdf().set(opt).from(element).output('datauristring')
        .then((response) => {
          const arr = response.split(',');
          const pfdStr = arr[1];
          const data = {
            insuranceId: this.id,
            pfdStr,
          };
          this.promotionalRewards[variant].sentXmlRequest = true;
          apiBackend.savePDF(data).then((r) => {
            this.$emit('add-to-queue', functionName, variant);
            this.onNextClick();
          });
          // console.log(response);
          this.pdfStyles = false;
        });
    },
    calculatePriceFormData(priceData) {
      const priceFormData = this.getPriceFormData();
      calculatePrice(this.insuranceData.spreadsheetData, priceFormData, priceData);
    },
    queueDownloadPDF() {
      this.$emit('add-to-queue', 'downloadPDF');
      this.onNextClick();
    },
    downloadPDF() {
      setTimeout(() => {
        apiBackend.downloadPDF(this.id).then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `Vorschlag-${this.id}.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
      }, 1000);
    },
    exportToPDF() {
      const filename = `Zusammenfassung-Montageversicherung-Preis-${this.id}.pdf`;
      this.pdfStyles = true;
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 10,
        filename,
        html2canvas: {
          width: 1500,
        },
      }).then((response) => {
        this.pdfStyles = false;
      });
    },
    goToBackUrl() {
      const routeName = getProductPageBeforePriceTabHelper(this.insuranceKey);

      this.$emit('go-back-tab', routeName, 'insuranceForms', 2);
    },
    setValues(priceFormData: any) {
      this.calculationResult = priceFormData.calculationResult;
      this.resultsOfTariffCalculation = priceFormData.resultsOfTariffCalculation;
      this.vorschadenhistorieSelect = priceFormData.vorschadenhistorieSelect;
      this.vorschadenhistorie = priceFormData.vorschadenhistorie;
      this.skillDiscounts = priceFormData.skillDiscounts;
      this.vierAugenPrinzip = priceFormData.vierAugenPrinzip;
      this.vertragAufMietumsatzbasis = priceFormData.vertragAufMietumsatzbasis;
      this.promotionalRewards = priceFormData.promotionalRewards;
      this.hinweiseZumBeitrag = priceFormData.hinweiseZumBeitrag;
    },
    getPriceFormData() {
      this.vorschadenhistorie.summe.schaden = this.vorschadenhistorieSummeSchaden;
      this.vorschadenhistorie.summe.reserven = this.vorschadenhistorieSummeReserven;
      this.vorschadenhistorie.MindestjahrespramieTarifVariante = this.claimsRequirementPremium;

      const data = {
        calculationResult: this.calculationResult,
        resultsOfTariffCalculation: this.resultsOfTariffCalculation,
        vorschadenhistorieSelect: this.vorschadenhistorieSelect,
        vorschadenhistorie: this.vorschadenhistorie,
        skillDiscounts: this.skillDiscounts,
        vierAugenPrinzip: this.vierAugenPrinzip,
        vertragAufMietumsatzbasis: this.vertragAufMietumsatzbasis,
        promotionalRewards: this.promotionalRewards,
        hinweiseZumBeitrag: this.hinweiseZumBeitrag,
      };
      return data;
    },
    onNextClick() {
      const data = this.getPriceFormData();
      this.$emit('price-formData-changed', data);
    },
    setEmtyBrutto() {
      const arrayPromotionalRewards = Object.entries(this.promotionalRewards);

      arrayPromotionalRewards.forEach(([groupKey, group]) => {
        const groupData: any = group;
        const arrayItem = Object.entries(groupData.items);

        arrayItem.forEach((subItem) => {
          const [key, value] = subItem;

          this.promotionalRewards[groupKey].items[key].offerPremiumGross = null;
        });
      });
    },
    initOffersRewards(data: any, fieldName = 'variant1') {
      const premiumAssemblySB = parseFloat(data.VertraglicheSelbstbeteiligungen[fieldName].SelbstbeteiligungMontage.SBBetrag);
      const premiumSampleSB = parseFloat(data.VertraglicheSelbstbeteiligungen[fieldName].SelbstbeteiligungProbebetrieb.SBBetrag);

      this.promotionalRewards[fieldName].items.premiumAssembly.sb = premiumAssemblySB;
      this.promotionalRewards[fieldName].items.premiumSample.sb = premiumSampleSB;
      this.promotionalRewards[fieldName].items.premiumMaintenance.sb = premiumAssemblySB;
      if (data.accessoriesRisk.sachenImGefahrenbereich === 'Ja' || data.accessoriesRisk.sachenImGefahrenbereich === 'optional') {
        this.promotionalRewards[fieldName].items.premiumSiG.sb = `${data.accessoriesRisk.selbstbeteiligungPercentage}%/${transformToCurrencyStr(+data.accessoriesRisk.selbstbeteiligungMindEUR)}`;
      } else {
        this.promotionalRewards[fieldName].items.premiumSiG.sb = `${0}%/${transformToCurrencyStr(+0)}`;
      }


      const val = `${transformToCurrencyStr(premiumAssemblySB)}/${transformToCurrencyStr(premiumSampleSB)}`;

      this.promotionalRewards[fieldName].items.premiumAssemblyIncludingSample.sb = val;
      this.promotionalRewards[fieldName].items.premiumTotal.sb = val;
      this.promotionalRewards[fieldName].items.premiumAdditionalCoverageWithoutSiG.sb = val;
    },
    inittResultsOfTariffCalculation(data: any) {
      const premiumAssemblySB = +data.VertraglicheSelbstbeteiligungen.variant1.SelbstbeteiligungMontage.SBBetrag;
      const premiumSampleSB = +data.VertraglicheSelbstbeteiligungen.variant1.SelbstbeteiligungProbebetrieb.SBBetrag;

      this.resultsOfTariffCalculation.variant1.deductible = `${transformToCurrencyStr(premiumAssemblySB)}/${transformToCurrencyStr(premiumSampleSB)}`;
    },
    parseDuration(value: any): number {
      // Parse the value to determine its type (Tage, Wochen, Monate)
      // Assuming value is in the format "10 Tage" or "3 Wochen" or "5 Monate"
      const amount = value.disabledValue;
      const unit = value.enabledValue;
      // eslint-disable-next-line radix
      const parsedAmount = parseInt(amount);

      switch (unit) {
        case 'Tage':
          return parsedAmount / 30; // Assuming 1 month = 30 days
        case 'Wochen':
          return parsedAmount / 4; // Assuming 1 month = 4 weeks
        case 'Monate':
          return parsedAmount;
        default:
          return parsedAmount;
      }
    },
  },
  computed: {
    locationsIsEuExcludingDeutschland(): boolean {
      const isLocation1EuWithoutD = this.insuranceData.basicData.versicherungsort.Ausland === 'Risikoort EU ohne D';
      const isLocation2EuWithoutD = this.insuranceData.basicData.versicherungsort2.Ausland === 'Risikoort EU ohne D';

      return isLocation1EuWithoutD || isLocation2EuWithoutD;
    },
    AssemblyInsuranceDuration(): number {
      if (this.insuranceData?.spreadsheetData) {
        const dauerDerMontage = this.parseDuration(this.insuranceData.spreadsheetData.montageversicherung.dauerDerMontageGesamt);
        const dauerDesProbebetriebs = this.parseDuration(this.insuranceData.spreadsheetData.montageversicherung.dauerDesProbebetriebsGesamt);

        return dauerDerMontage + dauerDesProbebetriebs;
      }
      return 0;
    },
    vorschadenhistorieSummeSchaden(): number {
      return (+this.vorschadenhistorie.jahr1.schaden) +
        (+this.vorschadenhistorie.jahr2.schaden) +
        (+this.vorschadenhistorie.jahr3.schaden) +
        (+this.vorschadenhistorie.jahr4.schaden) +
        (+this.vorschadenhistorie.jahr5.schaden);
    },
    vorschadenhistorieSummeReserven(): number {
      return (+this.vorschadenhistorie.jahr1.reserven) +
        (+this.vorschadenhistorie.jahr2.reserven) +
        (+this.vorschadenhistorie.jahr3.reserven) +
        (+this.vorschadenhistorie.jahr4.reserven) +
        (+this.vorschadenhistorie.jahr5.reserven);
    },
    // Schadenbedarfsprämie
    claimsRequirementPremium(): number {
      return calculateClaimsRequirementPremium(
        this.vorschadenhistorieSummeSchaden,
        this.vorschadenhistorieSummeReserven,
        +this.vorschadenhistorie.Betrachtungszeitraum,
        +this.vorschadenhistorie.Zielschadenquote,
      );
    },
    vertragsartIsAnmeldevertragOrUmsatzvertrag(): boolean {
      return (this.insuranceData.basicData.vertragsart === 'Anmeldevertrag' || this.insuranceData.basicData.vertragsart === 'Umsatzvertrag');
    },
    vertragsartIsKDS(): boolean {
      return (this.insuranceData.basicData.vertragsart === 'KDS');
    },
    isApiUser(): boolean {
      return this.$store.getters['authModule/isApiUser'];
    },
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
  },
  beforeDestroy() {
    if (this.isPCUser) return;
    this.onNextClick();
  },

});
