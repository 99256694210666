/* eslint-disable */
// @ts-ignore



import {transformToCurrencyStr} from "@/helper/functions";

function surchargesDiscountsCalculationForVariant(premiumTotalPreDiscount: number, Zusatzrisiken: any, exclusion: number | null): number {
  let result = Number(premiumTotalPreDiscount);
  result += Number(Zusatzrisiken.mitversicherungAutokraneNachTK7103.zuschlag);
  result += Number(Zusatzrisiken.mitversicherungSchwimmendeSachenNachTK7104.zuschlag);
  result += Number(Zusatzrisiken.eigentumDesMontagepersonalsNachTK7105.zuschlag);
  result *= (1 - (Number(exclusion)) / 100);
  return Number(result.toFixed(2));
}

function getPremiumIncludingForVariant(surchargesDiscounts: number, tariffCalculationItem: any): number {
  let result = Number(surchargesDiscounts);

  if (tariffCalculationItem.duration === 3) {
    result *= (1 - (10 / 100));
  }

  const { generalContractEstate, svr, discountForKDSCoverage, netting } = tariffCalculationItem;
  result *= (1 - (Number(generalContractEstate ?? 0) / 100));
  result *= (1 - (Number(svr ?? 0) / 100));
  result *= (1 - (Number(discountForKDSCoverage ?? 0) / 100));
  result *= (1 - (Number(netting ?? 0) / 100));

  return Number(result.toFixed(2));
}



function calculatePrice(spreadsheetData: any, priceFormData: any, priceData: any): void {

  Object.keys(priceFormData.resultsOfTariffCalculation).forEach((key) => {
    if (
      typeof priceData[key].priceResponse !== 'object' ||
      priceData[key].priceResponse.length === 0 ||
      !priceData[key].priceResponse.Result
    ) {
      return;
    }
    const premiumOverview = priceData[key].priceResponse.Result['Prämienübersicht'];
    const gesamtversicherungssumme = Number(spreadsheetData.versicherungssummeGesamt.gesamtversicherungssumme);
    // eslint-disable-next-line no-param-reassign

    priceFormData.resultsOfTariffCalculation[key].tariffPremium = surchargesDiscountsCalculationForVariant(premiumOverview['Prämie_Gesamt_VorRabatt'], spreadsheetData.Zusatzrisiken, spreadsheetData.Deckungseinschrankungen.nachlass);
    priceFormData.resultsOfTariffCalculation[key].premiumIncludingTariffDiscounts = getPremiumIncludingForVariant(priceFormData.resultsOfTariffCalculation[key].tariffPremium, priceFormData.resultsOfTariffCalculation[key]);

    priceFormData.promotionalRewards[key].items.premiumAssembly.premiumIncludingTariffDiscounts = getPremiumIncludingForVariant(Number(premiumOverview['Prämie_Montage']), priceFormData.resultsOfTariffCalculation[key]);
    priceFormData.promotionalRewards[key].items.premiumAssembly.premiumIncludingMarketFactor =  Number((priceFormData.promotionalRewards[key].items.premiumAssembly.premiumIncludingTariffDiscounts * skillDiscountsForVariant(priceFormData.skillDiscounts[key])).toFixed(2));
    priceFormData.promotionalRewards[key].items.premiumAssembly.netOfferPremium = Number((priceFormData.promotionalRewards[key].items.premiumAssembly.premiumIncludingMarketFactor * (1 - Number(priceFormData.vorschadenhistorie.ZusatzlicherVorschadenfaktor) / 100 )).toFixed(2));
    if(gesamtversicherungssumme) {
      priceFormData.promotionalRewards[key].items.premiumAssembly.netOfferPremiumRate = Number((priceFormData.promotionalRewards[key].items.premiumAssembly.netOfferPremium / gesamtversicherungssumme * 1000).toFixed(2));
    } else {
      priceFormData.promotionalRewards[key].items.premiumAssembly.netOfferPremiumRate = 0;
    }
    priceFormData.promotionalRewards[key].items.premiumAssembly.offerPremiumGross = Number((priceFormData.promotionalRewards[key].items.premiumAssembly.netOfferPremium * 1.19).toFixed(2));

    priceFormData.promotionalRewards[key].items.premiumSample.premiumIncludingTariffDiscounts = getPremiumIncludingForVariant(Number(premiumOverview['Prämie_Probe']), priceFormData.resultsOfTariffCalculation[key]);
    priceFormData.promotionalRewards[key].items.premiumSample.premiumIncludingMarketFactor = Number((priceFormData.promotionalRewards[key].items.premiumSample.premiumIncludingTariffDiscounts * skillDiscountsForVariant(priceFormData.skillDiscounts[key])).toFixed(2));
    priceFormData.promotionalRewards[key].items.premiumSample.netOfferPremium = Number((priceFormData.promotionalRewards[key].items.premiumSample.premiumIncludingMarketFactor * (1 - Number(priceFormData.vorschadenhistorie.ZusatzlicherVorschadenfaktor) / 100 )).toFixed(2));
    if(gesamtversicherungssumme) {
      priceFormData.promotionalRewards[key].items.premiumSample.netOfferPremiumRate = Number((priceFormData.promotionalRewards[key].items.premiumSample.netOfferPremium / gesamtversicherungssumme * 1000).toFixed(2));
    } else {
      priceFormData.promotionalRewards[key].items.premiumSample.netOfferPremiumRate = 0;
    }
    priceFormData.promotionalRewards[key].items.premiumSample.offerPremiumGross = Number((priceFormData.promotionalRewards[key].items.premiumSample.netOfferPremium * 1.19).toFixed(2));


    let coverageWithoutSiGsurcharges = surchargesDiscountsCalculationForVariant(premiumOverview['Prämie_ErstRisikoDeckungen'], spreadsheetData.Zusatzrisiken, spreadsheetData.Deckungseinschrankungen.nachlass);
    coverageWithoutSiGsurcharges += Number( spreadsheetData.MitversicherungAufErstesRisiko.zuschlagFurDieDeckungserweiterungNachTK7209.value);
    priceFormData.promotionalRewards[key].items.premiumAdditionalCoverageWithoutSiG.premiumIncludingTariffDiscounts = getPremiumIncludingForVariant(coverageWithoutSiGsurcharges, priceFormData.resultsOfTariffCalculation[key]);
    priceFormData.promotionalRewards[key].items.premiumAdditionalCoverageWithoutSiG.premiumIncludingMarketFactor = Number((priceFormData.promotionalRewards[key].items.premiumAdditionalCoverageWithoutSiG.premiumIncludingTariffDiscounts * skillDiscountsForVariant(priceFormData.skillDiscounts[key])).toFixed(2));
    priceFormData.promotionalRewards[key].items.premiumAdditionalCoverageWithoutSiG.netOfferPremium = Number((priceFormData.promotionalRewards[key].items.premiumAdditionalCoverageWithoutSiG.premiumIncludingMarketFactor * (1 - Number(priceFormData.vorschadenhistorie.ZusatzlicherVorschadenfaktor) / 100 )).toFixed(2));
    if(gesamtversicherungssumme) {
      priceFormData.promotionalRewards[key].items.premiumAdditionalCoverageWithoutSiG.netOfferPremiumRate = Number((priceFormData.promotionalRewards[key].items.premiumAdditionalCoverageWithoutSiG.netOfferPremium / gesamtversicherungssumme * 1000).toFixed(2));
    } else {
      priceFormData.promotionalRewards[key].items.premiumAdditionalCoverageWithoutSiG.netOfferPremiumRate = 0;
    }
    priceFormData.promotionalRewards[key].items.premiumAdditionalCoverageWithoutSiG.offerPremiumGross = Number((priceFormData.promotionalRewards[key].items.premiumAdditionalCoverageWithoutSiG.netOfferPremium * 1.19).toFixed(2));


    priceFormData.promotionalRewards[key].items.premiumAssemblyIncludingSample.premiumIncludingTariffDiscounts = Number((priceFormData.promotionalRewards[key].items.premiumAssembly.premiumIncludingTariffDiscounts + priceFormData.promotionalRewards[key].items.premiumSample.premiumIncludingTariffDiscounts + priceFormData.promotionalRewards[key].items.premiumAdditionalCoverageWithoutSiG.premiumIncludingTariffDiscounts).toFixed(2));
    priceFormData.promotionalRewards[key].items.premiumAssemblyIncludingSample.premiumIncludingMarketFactor = Number((priceFormData.promotionalRewards[key].items.premiumAssemblyIncludingSample.premiumIncludingTariffDiscounts * skillDiscountsForVariant(priceFormData.skillDiscounts[key])).toFixed(2));
    priceFormData.promotionalRewards[key].items.premiumAssemblyIncludingSample.netOfferPremium = Number((priceFormData.promotionalRewards[key].items.premiumAssemblyIncludingSample.premiumIncludingMarketFactor * (1 - Number(priceFormData.vorschadenhistorie.ZusatzlicherVorschadenfaktor) / 100 )).toFixed(2));
    if(gesamtversicherungssumme) {
      priceFormData.promotionalRewards[key].items.premiumAssemblyIncludingSample.netOfferPremiumRate = Number((priceFormData.promotionalRewards[key].items.premiumAssemblyIncludingSample.netOfferPremium / gesamtversicherungssumme * 1000).toFixed(2));
    } else {
      priceFormData.promotionalRewards[key].items.premiumAssemblyIncludingSample.netOfferPremiumRate = 0;
    }
    priceFormData.promotionalRewards[key].items.premiumAssemblyIncludingSample.offerPremiumGross = Number((priceFormData.promotionalRewards[key].items.premiumAssemblyIncludingSample.netOfferPremium * 1.19).toFixed(2));


    // let bonusExMaintenance = Number(premiumOverview['Prämie_ex_Maintenance'])
    // if (bonusExMaintenance === 0){
    let bonusExMaintenance = getPremiumIncludingForVariant(Number(premiumOverview['Prämie_Maintenance']), priceFormData.resultsOfTariffCalculation[key]);
    // }
    priceFormData.promotionalRewards[key].items.premiumMaintenance.premiumIncludingTariffDiscounts = bonusExMaintenance;
    priceFormData.promotionalRewards[key].items.premiumMaintenance.premiumIncludingMarketFactor = Number((priceFormData.promotionalRewards[key].items.premiumMaintenance.premiumIncludingTariffDiscounts * skillDiscountsForVariant(priceFormData.skillDiscounts[key])).toFixed(2));
    priceFormData.promotionalRewards[key].items.premiumMaintenance.netOfferPremium = Number((priceFormData.promotionalRewards[key].items.premiumMaintenance.premiumIncludingMarketFactor * (1 - Number(priceFormData.vorschadenhistorie.ZusatzlicherVorschadenfaktor) / 100 )).toFixed(2));
    if(gesamtversicherungssumme) {
      priceFormData.promotionalRewards[key].items.premiumMaintenance.netOfferPremiumRate = Number((priceFormData.promotionalRewards[key].items.premiumMaintenance.netOfferPremium / gesamtversicherungssumme * 1000).toFixed(2));
    } else {
      priceFormData.promotionalRewards[key].items.premiumMaintenance.netOfferPremiumRate = 0;
    }
    priceFormData.promotionalRewards[key].items.premiumMaintenance.offerPremiumGross = Number((priceFormData.promotionalRewards[key].items.premiumMaintenance.netOfferPremium * 1.19).toFixed(2));

    if (spreadsheetData.accessoriesRisk.sachenImGefahrenbereich === 'Ja' || spreadsheetData.accessoriesRisk.sachenImGefahrenbereich === 'optional') {
      priceFormData.promotionalRewards[key].items.premiumSiG.premiumIncludingTariffDiscounts = getPremiumSiG(spreadsheetData.accessoriesRisk);
    } else {
      priceFormData.promotionalRewards[key].items.premiumSiG.premiumIncludingTariffDiscounts = 0;
    }
    priceFormData.promotionalRewards[key].items.premiumSiG.premiumIncludingMarketFactor = Number((priceFormData.promotionalRewards[key].items.premiumSiG.premiumIncludingTariffDiscounts * skillDiscountsForVariant(priceFormData.skillDiscounts[key])).toFixed(2));
    priceFormData.promotionalRewards[key].items.premiumSiG.netOfferPremium = Number((priceFormData.promotionalRewards[key].items.premiumSiG.premiumIncludingMarketFactor * (1 - Number(priceFormData.vorschadenhistorie.ZusatzlicherVorschadenfaktor) / 100 )).toFixed(2));
    if(spreadsheetData.accessoriesRisk.sachenImGefahrenbereich && Number(spreadsheetData.accessoriesRisk.erstrisikosumme) !== 0) {
      priceFormData.promotionalRewards[key].items.premiumSiG.netOfferPremiumRate = Number((priceFormData.promotionalRewards[key].items.premiumSiG.netOfferPremium / Number(spreadsheetData.accessoriesRisk.erstrisikosumme) * 1000).toFixed(2));
    } else {
      priceFormData.promotionalRewards[key].items.premiumSiG.netOfferPremiumRate = 0;
    }
    priceFormData.promotionalRewards[key].items.premiumSiG.offerPremiumGross = Number((priceFormData.promotionalRewards[key].items.premiumSiG.netOfferPremium * 1.19).toFixed(2));


    priceFormData.promotionalRewards[key].items.premiumTotal.premiumIncludingTariffDiscounts = Number((priceFormData.promotionalRewards[key].items.premiumAssemblyIncludingSample.premiumIncludingTariffDiscounts + bonusExMaintenance + priceFormData.promotionalRewards[key].items.premiumSiG.premiumIncludingTariffDiscounts).toFixed(2));
    priceFormData.promotionalRewards[key].items.premiumTotal.premiumIncludingMarketFactor = Number((priceFormData.promotionalRewards[key].items.premiumTotal.premiumIncludingTariffDiscounts * skillDiscountsForVariant(priceFormData.skillDiscounts[key])).toFixed(2));
    priceFormData.promotionalRewards[key].items.premiumTotal.netOfferPremium = Number((priceFormData.promotionalRewards[key].items.premiumTotal.premiumIncludingMarketFactor * (1 - Number(priceFormData.vorschadenhistorie.ZusatzlicherVorschadenfaktor) / 100 )).toFixed(2));
    if(gesamtversicherungssumme) {
      priceFormData.promotionalRewards[key].items.premiumTotal.netOfferPremiumRate = Number((priceFormData.promotionalRewards[key].items.premiumTotal.netOfferPremium / gesamtversicherungssumme * 1000).toFixed(2));
    } else {
      priceFormData.promotionalRewards[key].items.premiumTotal.netOfferPremiumRate = 0;
    }
    priceFormData.promotionalRewards[key].items.premiumTotal.offerPremiumGross = Number((priceFormData.promotionalRewards[key].items.premiumTotal.netOfferPremium * 1.19).toFixed(2));

  });
}
function getPremiumSiG(accessoriesRisk: any): number {
  let result = (Number(accessoriesRisk.beitragssatzZuschlag)/1000) * (Number(accessoriesRisk.erstrisikosumme));
  if(result == 0) {
    result = Number(accessoriesRisk.zuschlag)
  }
  return Number(result.toFixed(2));
}
function skillDiscountsForVariant(variant: any): number {
  let result = 1;
  result *= (1 - Number(variant.estates)/100);
  result *= (1 + Number(variant.surcharges)/100);
  return result;
}
// function promotionalRewardsItem(promotionalRewards: any): object {
//
//   promotionalRewards[key].items.premiumAssembly.premiumIncludingTariffDiscounts = getPremiumIncludingForVariant(Number(premiumOverview['Prämie_Montage']), resultsOfTariffCalculation[key]);
//   promotionalRewards[key].items.premiumAssembly.premiumIncludingMarketFactor = promotionalRewards[key].items.premiumAssembly.premiumIncludingTariffDiscounts * skillDiscountsForVariant(skillDiscounts[key]);
//
//   return promotionalRewards;
// }

export {
  calculatePrice
};
